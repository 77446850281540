import React, {useState, useEffect} from "react";

const Flyers = () => {
  const [quantity, setQuantity] = useState("");
  const [selectedOption, setSelectedOption] = useState("One side");
  const [totalCost, setTotalCost] = useState(0);
  const [selectedSize, setSelectedSize] = useState("A3");

  useEffect(() => {
    handleCalculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, selectedOption, selectedSize]);

  const handleCalculate = () => {
    let quantityValue = parseInt(quantity);
    if (isNaN(quantityValue) || quantityValue < 1) {
      quantityValue = 1;
      setQuantity("");
    }

    let sheets = 0;

    switch (selectedSize) {
      case "A3":
        sheets = quantityValue / 1;
        break;
      case "A4":
        sheets = quantityValue / 2;
        break;
      case "A5":
        sheets = quantityValue / 4;
        break;
      case "A6":
        sheets = quantityValue / 8;
        break;
      case "A7":
        sheets = quantityValue / 10;
        break;
      default:
        sheets = quantityValue / 1;
        break;
    }

    let OneSide = 0;
    let TwoSide = 0;
    switch (selectedSize) {
      case "A5":
        OneSide = 0.58;
        TwoSide = 0.76;
        break;
      case "A4":
        OneSide = 0.38;
        TwoSide = 0.56;
        break;
      default:
        OneSide = 0.4;
        TwoSide = 0.6;
        break;
    }
    let printingCost = 0;
    switch (selectedOption) {
      case "One side":
        printingCost = OneSide;
        break;
      case "Double side":
        printingCost = TwoSide;
        break;
      default:
        break;
    }
    let cuttingFixCost = 10;
    let totalCuttingCost = Math.ceil(sheets) * 0.1;
    let othersCost = selectedSize === "A3" ? 10 : 0;
    let totalCost = Math.ceil(sheets) * printingCost + othersCost + totalCuttingCost + cuttingFixCost;
    let operatingCost = totalCost * 0.1; // 10% operating cost
    totalCost += operatingCost;
    totalCost = selectedSize === "A5" ? totalCost / 0.7 : totalCost / 0.5;

    setTotalCost(Math.ceil(totalCost));
  };

  const handleReset = () => {
    setQuantity("");
    setTotalCost(0);
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => {
      const parsedPrevQuantity = parseInt(prevQuantity);
      const newValue = isNaN(parsedPrevQuantity) ? 100 : parsedPrevQuantity + 100;
      return String(newValue);
    });
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => {
      const newValue = Math.max(parseInt(prevQuantity) - 100, 0); // Ensure minimum value of 50
      return String(newValue);
    });
  };

  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3 ">
            <label htmlFor="options" className="form-label">
              Choose Printing:
            </label>
            <select id="options" className="form-select" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
              <option value="One side">One side</option>
              <option value="Double side">Double side</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="size" className="form-label">
              Choose Size:
            </label>
            <select id="size" className="form-select" value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
              <option value="A3">A3</option>
              <option value="A4">A4</option>
              <option value="A5">A5</option>
              <option value="A6">A6</option>
              <option value="A7">A7</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="quantity" className="form-label">
              Quantity:
            </label>
            <div className="input-group">
              <input type="number" id="quantity" className="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} min="100" />
              <button className="btn btn-secondary mx-1" onClick={handleDecrement}>
                -
              </button>
              <button className="btn btn-secondary" onClick={handleIncrement}>
                +
              </button>
            </div>
          </div>
          <div className="mb-3">
            {quantity !== "" && (
              <>
                <button className="btn btn-secondary me-2">Total: {totalCost.toFixed(2)}</button>
                <button className="btn btn-danger" onClick={handleReset}>
                  Reset
                </button>
                <p className="font fst-italic py-3">"Great finishing with 170 GSM!!""</p>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>
    </div>
  );
};

export default Flyers;
