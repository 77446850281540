import {useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Flyers from "./pages/Flyers";
import Booklet from "./pages/booklet";
import Tshirts from "./pages/tshirts";

function App() {
  const [selectedPage, setSelectedPage] = useState(""); // State to track selected page

  return (
    <div className="container my-5 ">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h4 className="text-center mb-4 text-success fw-bold">Get Approximate Product Cost</h4>
          <div className="input-group mb-3 ">
            <select className="form-select fw-bold" value={selectedPage} onChange={(e) => setSelectedPage(e.target.value)}>
              <option value="">Select one..</option>
              <option value="tshirts">T-shirts</option>
              <option value="flyers">Flyers</option>
              <option value="booklet">Booklet</option>
            </select>
          </div>

          {selectedPage === "flyers" && <Flyers />}
          {selectedPage === "booklet" && <Booklet />}
          {selectedPage === "tshirts" && <Tshirts />}
        </div>
      </div>
    </div>
  );
}

export default App;
