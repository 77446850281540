/* eslint-disable default-case */
import React, {useState, useEffect} from "react";

const Tshirts = () => {
  const [quantity, setQuantity] = useState("");
  const [optionState, setOptionState] = useState("Front Small");
  const [totalCost, setTotalCost] = useState(0);
  const [selectedPrinttype, setSelectedPrinttype] = useState("UV Print");
  const [selectedProductName, setSelectedProductName] = useState("Round T-shirt");
  const [showAlert, setShowAlert] = useState(false);
  const [showTotalCost, setShowTotalCost] = useState(false);
  const [selectedColourOption, setSelectedColourOption] = useState("One Colour");
  // eslint-disable-next-line no-unused-vars
  const [selectedSize, setSelectedSize] = useState("S-XXL");

  let tshirtCost = 10;
  if (selectedSize === "3XL-5XL") {
    tshirtCost = 12;
  }
  useEffect(() => {
    if (quantity !== "" && selectedProductName !== "" && optionState !== "" && selectedSize !== "") {
      let optionCost = 0;
      switch (selectedPrinttype) {
        case "UV Print":
          switch (optionState) {
            case "Front Small":
              optionCost = quantity * 2 + 10;
              break;
            case "Front Big":
              optionCost = quantity * 4 + 10;
              break;
            case "Back Big":
              optionCost = quantity * 4 + 10;
              break;
            case "Front Small + Back Big":
              optionCost = quantity * 6 + 20;
              break;
            case "Front Big + Back Big":
              optionCost = quantity * 8 + 20;
              break;
            case "Front Small + One Sleeve":
              optionCost = quantity * 4 + 20;
              break;
            case "Front Small + One Sleeve + Back Big":
              optionCost = quantity * 8 + 30;
              break;
            default:
              optionCost = 0;
          }
          break;
        case "Screen Print":
          switch (selectedColourOption) {
            case "One Colour":
              switch (optionState) {
                case "Front Small":
                  optionCost = quantity * 0.5 + 150;
                  break;
                case "Front Big":
                  optionCost = quantity * 1 + 150;
                  break;
                case "Back Big":
                  optionCost = quantity * 1 + 150;
                  break;
                case "Front Small + Back Big":
                  optionCost = quantity * 1.5 + 300;
                  break;
                case "Front Big + Back Big":
                  optionCost = quantity * 2 + 300;
                  break;
                case "Front Small + One Sleeve":
                  optionCost = quantity * 1 + 300;
                  break;
                case "Front Small + One Sleeve + Back Big":
                  optionCost = quantity * 2 + 450;
                  break;
                // Add cases for other print areas if needed
              }
              break;
            case "Two Colour":
              switch (optionState) {
                case "Front Small":
                  optionCost = quantity * 1 + 300;
                  break;
                case "Front Big":
                  optionCost = quantity * 2 + 300;
                  break;
                case "Back Big":
                  optionCost = quantity * 2 + 300;
                  break;
                case "Front Small + Back Big":
                  optionCost = quantity * 3 + 600;
                  break;
                case "Front Big + Back Big":
                  optionCost = quantity * 4 + 600;
                  break;
                case "Front Small + One Sleeve":
                  optionCost = quantity * 2 + 600;
                  break;
                case "Front Small + One Sleeve + Back Big":
                  optionCost = quantity * 4 + 900;
                  break;
              }
              break;
            case "Three Colour":
              switch (optionState) {
                case "Front Small":
                  optionCost = quantity * 1.5 + 450;
                  break;
                case "Front Big":
                  optionCost = quantity * 3 + 450;
                  break;
                case "Back Big":
                  optionCost = quantity * 3 + 450;
                  break;
                case "Front Small + Back Big":
                  optionCost = quantity * 4.5 + 900;
                  break;
                case "Front Big + Back Big":
                  optionCost = quantity * 6 + 900;
                  break;
                case "Front Small + One Sleeve":
                  optionCost = quantity * 3 + 900;
                  break;
                case "Front Small + One Sleeve + Back Big":
                  optionCost = quantity * 6 + 1350;
                  break;
              }
              break;
            case "Four Colour":
              switch (optionState) {
                case "Front Small":
                  optionCost = quantity * 2 + 600;
                  break;
                case "Front Big":
                  optionCost = quantity * 4 + 600;
                  break;
                case "Back Big":
                  optionCost = quantity * 4 + 600;
                  break;
                case "Front Small + Back Big":
                  optionCost = quantity * 6 + 1200;
                  break;
                case "Front Big + Back Big":
                  optionCost = quantity * 8 + 1200;
                  break;
                case "Front Small + One Sleeve":
                  optionCost = quantity * 4 + 1200;
                  break;
                case "Front Small + One Sleeve + Back Big":
                  optionCost = quantity * 8 + 1800;
                  break;
              }
              break;
            default:
              optionCost = 0;
          }
          break;
        default:
          optionCost = 0;
      }

      let total = tshirtCost * quantity + optionCost;

      total = Math.round(total.toFixed(1));

      setTotalCost(total);
      setShowTotalCost(true);
    } else {
      setTotalCost(0);
      setShowTotalCost(false);
    }
  }, [quantity, tshirtCost, optionState, selectedProductName, selectedPrinttype, selectedColourOption, selectedSize]);

  const handleReset = () => {
    setQuantity("");
    setTotalCost(0);
    setShowTotalCost(false);
  };

  const handlePrintAreaChange = (e) => {
    setOptionState(e.target.value);
    setShowAlert(false);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setQuantity(newQuantity);
    if (newQuantity !== "" && optionState === "") {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  };
  const handleNameChange = (e) => {
    setSelectedProductName(e.target.value);
  };
  const handleColourOptionChange = (e) => {
    setSelectedColourOption(e.target.value);
  };

  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="productName" className="form-label">
              Choose Product:
            </label>
            <select id="productName" className="form-select" value={selectedProductName} onChange={handleNameChange}>
              <option value="Round T-shirt">Round T-shirt</option>
              <option value="Polo T-shirt">Polo T-shirt</option>
            </select>
          </div>
          <div>
            <div className="mb-3">
              <label htmlFor="printtype" className="form-label">
                Choose Print Type:
              </label>
              <select id="printtype" className="form-select" value={selectedPrinttype} onChange={(e) => setSelectedPrinttype(e.target.value)}>
                <option value="UV Print">UV Print</option>
                <option value="Screen Print">Screen Print</option>
                <option value="Digital Print">Digital Print</option>
                <option value="Emboidery Print">Emboidery Print</option>
              </select>
            </div>
            <div className="mb-3">
              {selectedPrinttype === "Screen Print" && (
                <div className="mb-3">
                  <label htmlFor="colourOption" className="form-label">
                    Choose Colour Option:
                  </label>
                  <select
                    id="colourOption"
                    className="form-select "
                    value={selectedColourOption}
                    onChange={handleColourOptionChange}
                    style={{
                      backgroundImage: selectedColourOption === "One Colour" ? "linear-gradient(to right, #00FF00 100%, #00FF00 0%)" : selectedColourOption === "Two Colour" ? "linear-gradient(to right, #00FF00 50%, #FFFF00 50%)" : selectedColourOption === "Three Colour" ? "linear-gradient(to right, #00FF00 30%, #FFFF00 50%, #FF6347 80%)" : selectedColourOption === "Four Colour" ? "linear-gradient(to right, #00FF00 20%, #FF33D4 65%, #FF6347 25%, #0000FF 90%)" : "",
                    }}>
                    <option value="One Colour">One Colour</option>
                    <option value="Two Colour">Two Colour</option>
                    <option value="Three Colour">Three Colour</option>
                    <option value="Four Colour">Four Colour</option>
                  </select>
                </div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="cornerState" className="form-label">
                Choose Print Area:
              </label>
              <select className="form-select" id="cornerState" value={optionState} onChange={handlePrintAreaChange} required>
                {selectedPrinttype === "Screen Print" && (
                  <>
                    <option value="Front Small">Front Small</option>
                    <option value="Front Big">Front Big</option>
                    <option value="Back Big">Back Big</option>
                    <option value="Front Small + Back Big">Front Small + Back Big</option>
                    <option value="Front Big + Back Big">Front Big + Back Big</option>
                    <option value="Front Small + One Sleeve">Front Small + One Sleeve</option>
                    <option value="Front Small + One Sleeve + Back Big">Front Small + One Sleeve + Back Big</option>
                  </>
                )}
                {selectedPrinttype === "UV Print" && (
                  <>
                    <option value="Small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                  </>
                )}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="quantity" className="form-label">
                Quantity:
              </label>
              <input type="number" id="quantity" className="form-control" value={quantity} onChange={handleQuantityChange} required min="1" />
            </div>
            <div className="mb-3">
              {showAlert && (
                <div className="alert alert-danger" role="alert">
                  Print Area is required!
                </div>
              )}
              {showTotalCost && selectedProductName === "Round T-shirt" && selectedPrinttype === "Screen Print" && (
                // Only render total cost when showTotalCost is true
                <>
                  <button className="btn btn-secondary me-2">Total: {totalCost.toFixed(2)}</button>
                  <button className="btn btn-danger" onClick={handleReset}>
                    Reset
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tshirts;
