import React, {useState, useEffect} from "react";

const Booklet = () => {
  const [quantity, setQuantity] = useState("");
  const [selectedSize, setSelectedSize] = useState("A4");
  const [totalCost, setTotalCost] = useState(0);
  const [selectedShape, setSelectedShape] = useState("Portrait");
  const [page, setPage] = useState("");
  const [coverPage, setCoverPage] = useState(4);
  const [innerPage, setInnerPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [clickedDirectly, setClickedDirectly] = useState(false);

  const handleReset = () => {
    setQuantity("");
    setTotalCost(0);
    setCoverPage(4);
    setInnerPage(0);
    setPage("");
  };

  const handleShapeChange = (e) => {
    setSelectedShape(e.target.value);
  };

  useEffect(() => {
    if (quantity !== "" && page !== "" && selectedSize !== "") {
      let divisionFactor = selectedSize === "A4" ? 4 : 8;
      let printCostCovervalue = selectedSize === "A4" ? 0.76 : 0.58;
      let printCostInnervalue = selectedSize === "A4" ? 0.61 : 0.48;
      let totalCoverSheet = (coverPage * quantity) / divisionFactor;
      let totalInnerSheet = (innerPage * quantity) / divisionFactor;
      let totalSheet = totalCoverSheet + totalInnerSheet;
      let printCostCover = totalCoverSheet * printCostCovervalue;
      let printCostInner = totalInnerSheet * printCostInnervalue;

      let totalCreasingCost = totalSheet * 0.5;
      let sheetCutting = totalSheet * 0.1;
      let totalStaplingCost = quantity * 2;
      let totalCost = printCostCover + printCostInner + totalCreasingCost + sheetCutting + totalStaplingCost + 30;
      totalCost /= 0.7;

      setTotalCost(Math.ceil(totalCost.toFixed(1)));
    } else {
      setTotalCost(0);
    }
  }, [quantity, page, coverPage, innerPage, selectedSize]);

  const handleTotalPageChange = (e) => {
    const value = parseInt(e.target.value);
    setPage(value);
    setInnerPage(value - coverPage); // Adjust inner page count based on cover page count
    setClickedDirectly(false); // Reset the flag when page count changes directly
  };

  const handleIncrement = () => {
    setPage((prevPage) => (prevPage !== "" ? prevPage + 4 : 4));
    setInnerPage(page !== "" ? page : 0); // Set inner page count to total pages or 4 if total pages are not set
    setClickedDirectly(true);
  };

  const handleDecrement = () => {
    setPage((prevPage) => (prevPage > 4 ? prevPage - 4 : ""));
    setInnerPage((prevInnerPage) => (prevInnerPage > 0 ? prevInnerPage - 4 : 0));
    setClickedDirectly(false); // Reset the flag when page count changes directly
  };

  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="productName" className="form-label">
              Choose Shape:
            </label>
            <select id="productName" className="form-select" value={selectedShape} onChange={handleShapeChange}>
              <option value="Portrait">Portrait</option>
              <option value="Landscape">Landscape</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="size" className="form-label">
              Choose Size:
            </label>
            <select id="size" className="form-select" value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
              <option value="A4">A4</option>
              <option value="A5">A5</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="page" className="form-label">
              Total Pages:
            </label>
            <div className="input-group">
              <input type="number" id="page" className="form-control" value={page} onChange={handleTotalPageChange} min="4" />
              <>
                <button className="btn btn-secondary fs-5 fw-bold" type="button" onClick={handleDecrement}>
                  -
                </button>
                <button className="btn btn-secondary mx-1 fw-bold fs-5" type="button" onClick={handleIncrement}>
                  +
                </button>
              </>
            </div>
          </div>
          {page !== "" && (
            <>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="coverPage" className="form-label">
                    Cover Page:
                  </label>
                  <input id="coverPage" className="form-control" value={coverPage} readOnly />
                </div>
                <div className="col">
                  <label htmlFor="innerPage" className="form-label">
                    Inner Page:
                  </label>
                  <input type="number" id="innerPage" className="form-control" value={innerPage} readOnly />
                </div>
              </div>
            </>
          )}
          <div className="mb-3">
            <label htmlFor="quantity" className="form-label">
              Quantity:
            </label>
            <input type="number" id="quantity" className="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} min="1" />
          </div>
          <div className="mb-3">
            {quantity !== "" && selectedShape === "Portrait" && page !== "" && (
              <>
                <button className="btn btn-secondary me-2">Total: {isNaN(totalCost) ? "0" : totalCost}</button>

                <button className="btn btn-danger" onClick={handleReset}>
                  Reset
                </button>
                <p className="font fst-italic py-3">"Great finishing with cover 250 GSM & inner 115 GSM!!"</p>
              </>
            )}
            {selectedShape === "Portrait" ? "" : <p className="font fst-italic py-3">"Landscape total calculating......"</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booklet;
